import React, { useEffect, useState, useCallback } from 'react';
import './customer.scss';
import 'devextreme-react/text-area';

import notify from 'devextreme/ui/notify';
import axios from 'axios';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import NumberBox from 'devextreme-react/number-box';
import { Button } from 'devextreme-react';
import { useScreenSize } from '../../utils/media-query';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const searchExpressionLabel = { 'aria-label': 'Search Expression' };

export default function CustomerOpening() {
    const [customerCode, setCustomerCode] = useState();
    const [cylinderData, setCylinderData] = useState([]);

    const [cylinderTypeCode, setCylinderTypeCode] = useState();
    const [cylinderTypeName, setCylinderTypeName] = useState();
    const [opAmount, setOpAmount] = useState(0);
    const [loaded, setLoaded] = useState(0);

    const [customers, setCustomers] = useState([]);
    const [cylinders, setCylinders] = useState([]);
    const { isXSmall, isLarge } = useScreenSize();

    useEffect(() => {
        axios({
            method: 'get',
            url: `${apiBaseUrl}/Customer/GetAll`,
        }).then(function (response) {
            if (response.data.Result == "Success") {
                setCustomers(JSON.parse(response.data.Data));
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'error', 3000);
            return [];
        });

        axios({
            method: 'get',
            url: `${apiBaseUrl}/CylinderType/GetAll`,
        }).then(function (response) {
            if (response.data.Result == "Success") {
                setCylinders(JSON.parse(response.data.Data));
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'error', 3000);
            return [];
        });
    }, [])

    const handleCustomerChanged = (e) => {
        setCustomerCode(e.value);
        if (e.value != null) {
            axios({
                method: 'get',
                url: `${apiBaseUrl}/Customer/GetOpeningBalance?CustomerCode=${e.value}`,
            }).then(function (response) {
                if (response.data.Result == "Success") {
                    const opData = JSON.parse(response.data.Data);

                    const Cylinders = [];
                    opData.forEach(dt => {
                        Cylinders.push({ CylinderTypeCode: dt.CylinderTypeCode, CylinderTypeName: dt.CylinderTypeName, OpeningAmount: dt.OpeningAmount, Loaded: dt.Loaded });
                    });

                    setCylinderData(Cylinders);
                } else {
                    notify({
                        message: response.data.Result,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 3000);
                }
            }).catch((error) => {
                notify({
                    message: "Error fetching data from API:", error,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 3000);
                return [];
            });
        }
    }

    const handleCylinderChange = (e) => {
        if (e.value != null) {
            const result = cylinders.filter((cyln) => cyln.CylinderTypeCode == e.value);
            setCylinderTypeCode(result[0].CylinderTypeCode);
            setCylinderTypeName(result[0].CylinderTypeName);
        } else {
            setCylinderTypeCode(null);
            setCylinderTypeName(null);
        }

    }

    const handleAddCylinder = (e) => {
        if (customerCode == null) {
            notify({
                message: "Select the Customer",
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'warning', 3000);
            return;
        }
        if (cylinderTypeCode == null) {
            notify({
                message: "Select the Cylinder Type", 
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'warning', 3000);
            return;
        }

        if ((opAmount == null || opAmount == 0) && (loaded == null || loaded == 0)) {
            notify({
                message: "Enter the Loaded or Opening Amount",
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'warning', 3000);
            return;
        }

        const result = cylinderData.filter((cyln) => cyln.CylinderTypeCode == cylinderTypeCode);
        if (result.length > 0) {
            notify({
                message: 'Already existing the Cylinder',
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'warning', 3000);
            return;
        }
        const cyln = [];
        cyln.push(...cylinderData, { CylinderTypeCode: cylinderTypeCode, CylinderTypeName: cylinderTypeName, OpeningAmount: opAmount, Loaded: loaded });
        setCylinderData(cyln);
        setCylinderTypeCode(null);
        setLoaded(null);
        setOpAmount(null);
    }

    const handleLoadedChange = useCallback((e) => {
        console.log(e);
        //const { name, value } = e.target;
        setLoaded(e);
    }, []);

    const handleOpAmountChange = useCallback((e) => {
        console.log(e);
        //const { name, value } = e.target;
        setOpAmount(e);
    }, []);


    const handleRemoveCylinder = (index) => {
        let result = [...cylinderData];
        result.splice(index, 1);
        setCylinderData(result);
    }

    const handleSubmit = () => {
        if (customerCode == null) {
            notify({
                message: 'Select the Customer',
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'warning', 3000);
            return;
        }


        axios({
            method: 'post',
            url: `${apiBaseUrl}/Customer/SaveCustomerOpening`,
            data: { CustomerCode: customerCode, Cylinder: cylinderData }
        }).then(function (response) {
            if (response.data.Result == "Success") {
                setCustomerCode(null);
                setOpAmount(null);
                setCylinderData([]);
                setCylinderTypeCode(null);
                setCylinderTypeName(null);
                setLoaded(null);
                notify({
                    message: 'Customer Opening updated successfully',
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'success', 3000);
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'error', 3000);
            return [];
        });
    }

    return (
        <React.Fragment>
            <h4 className={'content-block'}>Customer Opening</h4>
            <div className="dx-fieldset" style={{ width: isXSmall ? `90%` : `50%` }}>
                <div className="dx-field">
                    <div className="dx-field-label">Customer Name</div>
                    <div className="dx-field-value">
                        <SelectBox items={customers}
                            searchEnabled={true}
                            inputAttr={searchExpressionLabel}
                            valueExpr={'CustomerCode'}
                            displayExpr={'CustomerName'}
                            onValueChanged={handleCustomerChanged}
                            value={customerCode}
                        />
                    </div>
                </div>



                <div className="dx-field">
                    <div className="dx-field-label">Cylinder</div>
                    <div className="dx-field-value" >
                        <SelectBox items={cylinders}
                            searchEnabled={true}
                            inputAttr={searchExpressionLabel}
                            valueExpr={'CylinderTypeCode'}
                            displayExpr={'CylinderTypeName'}
                            onValueChanged={handleCylinderChange}
                            value={cylinderTypeCode}
                        />
                    </div>
                </div>

                <div className="dx-field">
                    <div className="dx-field-label">Opening Amount</div>
                    <div className="dx-field-value" style={{ display: 'flex' }}>
                        <div style={{ paddingRight: 57 }}>
                            <NumberBox
                                value={opAmount}
                                onValueChange={handleOpAmountChange} />
                        </div>
                    </div>
                </div>

                <div className="dx-field">
                    <div className="dx-field-label">Loaded</div>
                    <div className="dx-field-value" style={{ display: 'flex' }}>
                        <div style={{ paddingRight: 10 }}>
                            <NumberBox
                                value={loaded}
                                onValueChange={handleLoadedChange} />
                        </div>

                        <div style={{ float: 'right' }}>
                            <Button text={'+'} onClick={handleAddCylinder} />
                        </div>


                    </div>
                </div>

                <table className='tbl' >
                    {cylinderData.length > 0 &&
                        <thead>
                            <tr style={{ backgroundColor: '#7ab8eb', textAlign: 'center', color: '#fff' }}>
                                <td>

                                </td>
                                <td style={{ width: 100, padding: 5 }}>
                                    Cylinder Type
                                </td>
                                <td style={{ width: 100, padding: 5 }} >
                                    Op. Amount
                                </td>
                                <td style={{ width: 100, padding: 5 }} >
                                    Loaded
                                </td>
                            </tr>
                        </thead>}
                    <tbody>
                        {cylinderData.map((cylinder, index) => (
                            <tr key={index} style={{ backgroundColor: '#EEEEEE' }}>
                                <td style={{ width: 30, padding: 5, cursor: 'pointer', textAlign: 'center', color: 'red' }} onClick={(e) => { handleRemoveCylinder(index) }}>
                                    X
                                </td>
                                <td style={{ width: 100, padding: 5 }}>
                                    {cylinder.CylinderTypeName}
                                </td>
                                <td style={{ width: 100, textAlign: 'right', padding: 5 }}>
                                    {cylinder.OpeningAmount}
                                </td>
                                <td style={{ width: 100, textAlign: 'center', padding: 5 }}>
                                    {cylinder.Loaded}
                                </td>
                            </tr>
                        )
                        )}
                    </tbody>

                </table >


                <div style={{ paddingTop: 20 }}>
                    <Button text={'Save'} onClick={handleSubmit} type="success" stylingMode="contained" />
                </div>
            </div>

            {/* <Box direction="row"
                width="100%"
                height={75}>
                <Item ratio={1}>
                    <div className="rect demo-dark">
                        ratio = 1
                    </div>
                </Item>

                <Item>
                    Customer Name
                    <SelectBox items={customers}
                        searchEnabled={true}
                        inputAttr={searchExpressionLabel}
                        valueExpr={'CustomerCode'}
                        displayExpr={'CustomerName'}
                        onValueChanged={handleValueChanged}
                    >
                    </SelectBox>
                </Item>

                <div className={'dx-card responsive-paddings'} style={{ border: 0 }}>




                    Opening Amount
                    <TextBox value={opAmount} />








                </div >
            </Box >  */}
        </React.Fragment >
    )
}
