import React, { useEffect } from 'react';
export default function Dashboard() {
    useEffect(() => {
        const getData = () => {
            console.log("UseEffect")
        }

        return () => getData();
    }, [])
    return <div>

    </div>
}