import React, { useEffect, useState, useCallback } from 'react';
import '../customer/customer.scss';
import 'devextreme-react/text-area';

import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import axios from 'axios';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import NumberBox from 'devextreme-react/number-box';
import { Button } from 'devextreme-react';
import { useScreenSize } from 'utils/media-query';
import DateBox from 'devextreme-react/date-box';
import { useAuth } from 'contexts/auth';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const searchExpressionLabel = { 'aria-label': 'Search Expression' };

export default function Sales() {
    const [customerCode, setCustomerCode] = useState();
    const [rate, setRate] = useState(null);
    const [cylinderData, setCylinderData] = useState([]);

    const [cylinderTypeCode, setCylinderTypeCode] = useState();
    const [cylinderTypeName, setCylinderTypeName] = useState();
    const [loaded, setLoaded] = useState(null);
    const [empty, setEmpty] = useState(null);

    const [billDate, setBillDate] = useState(new Date());
    const [amount, setAmount] = useState(null);
    const [balanceAmount, setBalanceAmount] = useState(null);
    const [receivedAmount, setReceivedAmount] = useState(null);
    const [netBalance, setNetBalance] = useState(null);

    const [customers, setCustomers] = useState([]);
    const [cylinders, setCylinders] = useState([]);
    const { isXSmall, isLarge } = useScreenSize();
    const [enaBillDate, setEnaBillDate] = useState(true);
    const { user } = useAuth();

    useEffect(() => {
        axios({
            method: 'get',
            url: `${apiBaseUrl}/Customer/GetAll`,
        }).then(function (response) {
            if (response.data.Result == "Success") {
                setCustomers(JSON.parse(response.data.Data));
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'error', 3000);
            return [];
        });

        axios({
            method: 'get',
            url: `${apiBaseUrl}/CylinderType/GetAll`,
        }).then(function (response) {
            if (response.data.Result == "Success") {
                setCylinders(JSON.parse(response.data.Data));
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'error', 3000);
            return [];
        });

        let roleCode = user.RoleCode;
        if (roleCode == 1) {
            setEnaBillDate(false);
        } else {
            setEnaBillDate(true);
        }
    }, [])



    const handleCustomerChanged = (e) => {
        setCustomerCode(e.value);
        if (e.value != null) {
            axios({
                method: 'get',
                url: `${apiBaseUrl}/Sales/GetCustomerBalance?CustomerCode=${e.value}`,
            }).then(function (response) {
                if (response.data.Result == "Success") {
                    const opData = JSON.parse(response.data.Data);
                    const Cylinders = [];
                    opData.forEach(dt => {
                        Cylinders.push({ CylinderTypeCode: dt.CylinderTypeCode, CylinderTypeName: dt.CylinderTypeName, Loaded: dt.Loaded, Balance: dt.Balance });
                    });
                    setCylinderData(Cylinders);
                } else {
                    notify({
                        message: response.data.Result,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 3000);
                }
            }).catch((error) => {
                notify({
                    message: "Error fetching data from API:", error,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 3000);
                return [];
            });
        }
    }

    const handleCylinderChange = (e) => {
        if (e.value != null) {
            const result = cylinders.filter((cyln) => cyln.CylinderTypeCode == e.value);
            setCylinderTypeCode(result[0].CylinderTypeCode);
            setCylinderTypeName(result[0].CylinderTypeName);
        } else {
            setCylinderTypeCode(null);
            setCylinderTypeName(null);
        }

    }

    const handleAddCylinder = (e) => {
        const result = cylinderData.filter((cyln) => cyln.CylinderTypeCode == cylinderTypeCode);
        if (result.length > 0) {
            notify({
                message: 'Already existing the Cylinder',
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'warning', 3000);
            return;
        }
        const cyln = [];
        cyln.push(...cylinderData, { CylinderTypeCode: cylinderTypeCode, CylinderTypeName: cylinderTypeName, Loaded: loaded });
        setCylinderData(cyln);
        setLoaded(null);
    }

    const handleRateChange = (e) => {
        setRate(e);
    }

    const handleLoadedChange = (e) => {
        setLoaded(e);
    }

    const handleEmptyChange = useCallback((e) => {
        setEmpty(e);
    }, []);

    const handleReceivedChange = useCallback((e) => {
        setReceivedAmount(e);
    }, []);

    const calBalAmount = () => {
        const result = cylinderData.filter((cyln) => cyln.CylinderTypeCode == cylinderTypeCode);
        setAmount(rate * loaded);
        setBalanceAmount(result[0]?.Balance == null ? 0 : result[0].Balance + (rate * loaded));
        setNetBalance(balanceAmount - receivedAmount);
    }

    useEffect(() => {
        calBalAmount();
    }, [rate, loaded, cylinderTypeCode, receivedAmount, balanceAmount])


    const handleSubmit = () => {
        if (customerCode == null) {
            notify({
                message: 'Select the Customer',
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'warning', 3000);
            return;
        }

        if (cylinderTypeCode == null) {
            notify({
                message: 'Select the Cylinder Type',
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'warning', 3000);
            return;
        }

        // const result = cylinderData.filter((cyln) => cyln.CylinderTypeCode == cylinderTypeCode);

        // if(result[0].Loaded < empty)
        // {
        //     notify({
        //         message: 'Empty cylinder should not be greater than ' + (result[0].Loaded),
        //         position: {
        //             my: 'center top',
        //             at: 'center top',
        //         },
        //     }, 'warning', 3000);
        //     return;
        // }

        if((loaded == null || loaded == 0) && (empty == null || empty == 0) && (receivedAmount == null || receivedAmount == 0))
        {
            notify({
                message: 'Please enter the details',
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'warning', 3000);
            return;
        }

        if(netBalance < 0)
        {
            confirm('Are you getting the amount as an Advance?', 'Confirm Changes').then((result) => {
                if (result) {
                    Save();
                } else {
                  return;
                }
              });
        }else{
            Save();
        }
    }

    const Save =()=> {
        const lData = {
            BillDate: billDate,
            CustomerCode: customerCode,
            SalesManCode: user.UserCode,
            Balance: balanceAmount,
            BillAmount: amount,
            Received: receivedAmount,
            FYCode: user.FYCode,
            User: user.UserCode,
            Details: [{
                CylinderTypeCode: cylinderTypeCode,
                Loaded: loaded,
                Empty: empty,
                Rate: rate,
                Amount: amount,
            }]
        }
        console.log(lData); 

        axios({
            method: 'post',
            url: `${apiBaseUrl}/Sales/Save`,
            data: lData
        }).then(function (response) {
            if (response.data.Result == "Success") {
                setCustomerCode(null);
                setRate(null);
                setCylinderData([]);
                setCylinderTypeCode(null);
                setCylinderTypeName(null);
                setLoaded(null);
                setEmpty(null);
                setReceivedAmount(null);
                notify({
                    message: 'Sales updated successfully',
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'success', 3000);
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'error', 3000);
            return [];
        });
    };

    return (
        <React.Fragment>
            <h4 className={'content-block'}>Sales</h4>
            <div className="dx-fieldset" style={{ width: isXSmall ? `90%` : `50%` }}>
                <div className="dx-field">
                    <div className="dx-field-label">Bill Date</div>
                    <div className="dx-field-value">
                        <DateBox
                            displayFormat={'dd/MM/yyyy'}
                            readOnly={enaBillDate}
                            value={billDate}
                        />
                    </div>
                </div>


                <div className="dx-field">
                    <div className="dx-field-label">Customer Name</div>
                    <div className="dx-field-value">
                        <SelectBox items={customers}
                            searchEnabled={true}
                            inputAttr={searchExpressionLabel}
                            valueExpr={'CustomerCode'}
                            displayExpr={'CustomerName'}
                            onValueChanged={handleCustomerChanged}
                            value={customerCode}
                        />
                    </div>
                </div>
                {cylinderData.length > 0 &&
                    <div>
                        <div className="dx-field-label"></div>
                        <table className='tbl' style={{ paddingBottom: 10 }}>

                            <thead>
                                <tr style={{ backgroundColor: '#7ab8eb', textAlign: 'center', color: '#fff' }}>
                                    <td style={{ width: 150, padding: 5 }}>
                                        Cylinder Type
                                    </td>
                                    <td style={{ width: 100, padding: 5 }} >
                                        Loaded
                                    </td>
                                    <td style={{ width: 100, padding: 5 }} >
                                        Balance
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {cylinderData.map((cylinder, index) => (
                                    <tr key={index} style={{ backgroundColor: '#EEEEEE' }}>
                                        <td style={{ width: 100, padding: 5 }}>
                                            {cylinder.CylinderTypeName}
                                        </td>
                                        <td style={{ width: 100, textAlign: 'center', padding: 5 }}>
                                            {cylinder.Loaded}
                                        </td>
                                        <td style={{ width: 100, textAlign: 'right', padding: 5 }}>
                                            {cylinder.Balance}
                                        </td>
                                    </tr>
                                )
                                )}
                            </tbody>

                        </table >
                    </div>
                }
                <div className="dx-field">
                    <div className="dx-field-label">Cylinder</div>
                    <div className="dx-field-value">
                        <SelectBox items={cylinders}
                            searchEnabled={true}
                            inputAttr={searchExpressionLabel}
                            valueExpr={'CylinderTypeCode'}
                            displayExpr={'CylinderTypeName'}
                            onValueChanged={handleCylinderChange}
                            value={cylinderTypeCode}
                        />
                    </div>
                </div>

                <div className="dx-field">
                    <div className="dx-field-label">Rate</div>
                    <div className="dx-field-value">
                        <NumberBox
                            value={rate}
                            onValueChange={handleRateChange} />
                    </div>
                </div>

                <div className="dx-field">
                    <div className="dx-field-label">Loaded</div>
                    <div className="dx-field-value">
                        <NumberBox
                            value={loaded}
                            onValueChange={handleLoadedChange} />
                    </div>
                </div>
                <div className="dx-field">
                    <div className="dx-field-label">Empty</div>
                    <div className="dx-field-value">
                        <NumberBox
                            value={empty}
                            onValueChange={handleEmptyChange} />
                    </div>
                </div>
                <div className="dx-field">
                    <div className="dx-field-label">Amount</div>
                    <div className="dx-field-value">
                        <NumberBox className="boldTextBox"
                            value={amount} readOnly={true} />
                    </div>
                </div>
                <div className="dx-field">
                    <div className="dx-field-label">Balance Amount</div>
                    <div className="dx-field-value">
                        <NumberBox className="boldTextBox"
                            value={balanceAmount} readOnly={true} />
                    </div>
                </div>

                <div className="dx-field">
                    <div className="dx-field-label">Received Amount</div>
                    <div className="dx-field-value">
                        <NumberBox
                            value={receivedAmount}
                            onValueChange={handleReceivedChange} />
                    </div>
                </div>

                <div className="dx-field">
                    <div className="dx-field-label">Balance</div>
                    <div className="dx-field-value">
                        <NumberBox className="boldTextBox"
                            value={netBalance} readOnly={true} />
                    </div>
                </div>

                <div style={{ paddingTop: 20 }}>
                    <Button text={'Submit'} onClick={handleSubmit} type="success" stylingMode="contained" />
                </div>
            </div>
        </React.Fragment >
    )
}
