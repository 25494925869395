import React, { useEffect, useState } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
    Button,
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Editing,
    Popup,
    Form,
    Item,
    RequiredRule
} from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import { json } from 'react-router-dom';
import { CheckBox } from 'devextreme-react';


export default function Role() {
    const [data, setData] = useState([]);

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    useEffect(() => {

        axios({
            method: 'get',
            url: `${apiBaseUrl}/Role/GetAll`,
        }).then(function (response) {
            if (response.data.Result == "Success") {
                setData(JSON.parse(response.data.Data));
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'right top',
                    at: 'right top',
                },
            }, 'error', 3000);
            return [];
        });
    }, [])

    const RemoveRow = (e) => {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        axios({
            method: 'post',
            url: `${apiBaseUrl}/Role/Delete`,
            data: e.data
        }).then(function (response) {
            if (response.data.Result == "Success") {
                notify({
                    message: 'Role is Deleted successfully',
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'warning', 3000);
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'right top',
                    at: 'right top',
                },
            }, 'error', 3000);
            return [];
        });
        //  e.preventDefault();
    }
    const AddRow = (e) => {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        axios({
            method: 'post',
            url: `${apiBaseUrl}/Role/Save`,
            data: e.data
        }).then(function (response) {

            if (response.data.Result == "Success") {
                notify({
                    message: 'Role is added successfully',
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'success', 3000);
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'right top',
                    at: 'right top',
                },
            }, 'error', 3000);
            return [];
        });
        //  e.preventDefault();
    }

    const RowUpdate = (e) => {

        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        axios({
            method: 'post',
            url: `${apiBaseUrl}/Role/Save`,
            data: e.data
        }).then(function (response) {
            if (response.data.Result == "Success") {
                notify({
                    message: 'Role is Updated successfully',
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'success', 3000);
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'right top',
                    at: 'right top',
                },
            }, 'error', 3000);
            return [];
        });
        //  e.preventDefault();

    }



    return (
        <React.Fragment>

            <h4 className={'content-block'}>Role Details</h4>

            <DataGrid
                className={'dx-card wide-card'}
                dataSource={data}
                keyExpr="RoleCode"
                showBorders={false}
                focusedRowEnabled={true}
                onRowUpdated={RowUpdate}
                onRowInserted={AddRow}
                onRowRemoved={RemoveRow}

            >

                <Editing
                    mode="form"
                    useIcons={true}
                    allowAdding={true}
                    allowUpdating={true}
                    allowDeleting={true}
                    GroupPanel={true}

                >
                    <Form>
                        <Item dataField="RoleName" />
                        <Item dataField="Status" />
                    </Form>
                </Editing>
                <Column type="buttons" width={70}>
                    <Button name="edit" />
                    <Button name="delete" />
                </Column>
                <Column
                    dataField={'RoleName'}
                    caption={'Role Name'}
                >
                    <RequiredRule />
                </Column>
                <Column
                    dataField={'Status'}
                    caption={'Status'}

                />

            </DataGrid>
        </React.Fragment>
    )
}


