import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest } from '../api/auth';


function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.data);
      }
      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (userId, password) => {
    const result = await sendSignInRequest(userId, password);
    if (result.isOk) {
      let data = JSON.parse(result.data);
      let user = {
        UserCode: data[0].UserCode,
        UserID: data[0].UserID,
        UserName: data[0].UserName,
        RoleCode: data[0].RoleCode,
        RoleName: data[0].RoleName,
        FYCode: data[0].FYCode
      }
      localStorage.setItem('User', JSON.stringify(user))
      setUser(user);
    }
    return result;
  }, []);

  const signOut = useCallback(() => {
    localStorage.setItem('User', null);
    setUser(undefined);
  }, []);


  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
