import React, { useEffect, useState } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
    Button,
    Column,
    ColumnChooser, ColumnChooserSearch, ColumnChooserSelection, Position,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Editing,
    Popup,
    Form,
    grid,
    Grouping,
    Export,
    Selection,
    GroupPanel,
    RequiredRule,
    PatternRule,
    EmailRule,
    Scrolling,
    LoadPanel
} from 'devextreme-react/data-grid';
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import { Item } from 'devextreme-react/form';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
import { useScreenSize } from '../../utils/media-query';
import { CheckBox } from 'devextreme-react';



export default function CustomerDetails() {
    const [data, setData] = useState([]);
    const [area, setArea] = useState([]);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const { isXSmall, isLarge } = useScreenSize();
    const [loader, setLoader] = useState(false);
    const customer = {
        Code: null,
        CustomerName: '',
        ContactPerson: '',
        Address1: '',
        Address2: '',
        City: '',
        Pincode: '',
        MobileNo: '',
        PhoneNo: '',
        EmailID: '',
        GSTNo: '',
        Status: true,
    };

    useEffect(() => {

        axios({
            method: 'get',
            url: `${apiBaseUrl}/Area/GetAll`,
        }).then(function (response) {
            if (response.data.Result == "Success") {
                setArea(JSON.parse(response.data.Data));
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'error', 3000);
            return [];
        });
        setLoader(true);
        axios({
            method: 'get',
            url: `${apiBaseUrl}/Customer/GetAll`,
        }).then(function (response) {
            setLoader(false);
            if (response.data.Result == "Success") {
                setData(JSON.parse(response.data.Data));
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            setLoader(false);
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'error', 3000);
            return [];
        });

    }, []);



    const RemoveRow = (e) => {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        axios({
            method: 'post',
            url: `${apiBaseUrl}/Customer/Delete`,
            data: e.data
        }).then(function (response) {
            if (response.data.Result == "Success") {
                notify({
                    message: 'Customer is Deleted successfully',
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'warning', 3000);
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'error', 3000);
            return [];
        });
        //  e.preventDefault();
    }
    const AddRow = (e) => {
        console.log(e.data);
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        axios({
            method: 'post',
            url: `${apiBaseUrl}/Customer/Save`,
            data: e.data
        }).then(function (response) {

            if (response.data.Result == "Success") {
                notify({
                    message: 'Customer is added successfully',
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'success', 3000);
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'right top',
                    at: 'right top',
                },
            }, 'error', 3000);
            return [];
        });
        //  e.preventDefault();
    }

    const RowUpdate = (e) => {
        console.log(e.data);
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        axios({
            method: 'post',
            url: `${apiBaseUrl}/Customer/Save`,
            data: e.data
        }).then(function (response) {
            if (response.data.Result == "Success") {
                notify({
                    message: 'Customer is Updated successfully',
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'success', 3000);
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'right top',
                    at: 'right top',
                },
            }, 'error', 3000);
            return [];
        });
        //  e.preventDefault();

    }
    const exportFormats = ['pdf'];
    const onExporting = React.useCallback((e) => {
        const doc = new jsPDF({orientation: 'l',format: 'a3',});

        exportDataGrid({
            jsPDFDocument: doc,
            component: e.component,
            //columnWidths: [60, 40, 35, 25, 15],
            indent: 5,
        }).then(() => {
            doc.save('CustomerDetails.pdf');
        });
    });

    const cellPrepared = (e) => {
        if (e.row && e.row.isSelected) {
            e.cellElement.style.backgroundColor = "green";
        }
    };

    const [statusText, setStatusText] = useState(true);

    const statusChanged = (e) => {
        if (e.value == true) {
            setStatusText("Active");
        } else {
            setStatusText("Inactive");
        }
    }
    const searchEditorOptions = { placeholder: 'Search column' };
    const allowedPageSizes = [10, 20];
    return (
        <React.Fragment>

            <h4 className={'content-block'}>Customer Details</h4>

            <DataGrid
                id="grid"
                className={'dx-card wide-card'}
                dataSource={data}
                keyExpr="CustomerCode"
                showBorders={true}
                focusedRowEnabled={true}
                onRowUpdated={RowUpdate}
                onRowInserted={AddRow}
                onRowRemoved={RemoveRow}
                onExporting={onExporting}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnResizingMode={'widget'}
                rowAlternationEnabled={true}
            >
                <Export enabled={true} formats={exportFormats} />
                <Scrolling columnRenderingMode='virtual' rowRenderingMode='standard'></Scrolling>
                <Paging defaultPageSize={10} />
                <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={'full'}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true} />
                <LoadPanel enabled={loader} />
                <GroupPanel visible={true} />
                <FilterRow visible={true} />
                <ColumnChooser
                    enabled={true}
                    mode={'select'}
                >
                    <Position
                        my="right top"
                        at="right bottom"
                        of=".dx-datagrid-column-chooser-button"
                    />

                    <ColumnChooserSearch
                        enabled={true}
                        editorOptions={searchEditorOptions} />

                    <ColumnChooserSelection
                        allowSelectAll={true}
                        selectByClick={true}
                        recursive={true} />
                </ColumnChooser>
                <Editing
                    mode="form"
                    useIcons={true}
                    allowAdding={true}
                    allowUpdating={true}
                    allowDeleting={true}
                    GroupPanel={true}
                >
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2}>
                            <Item dataField="CustomerName" />
                            <Item dataField="ContactPerson" />

                            <Item dataField="Address1" />
                            <Item dataField="Address2" />
                            <Item dataField="City" />
                            <Item dataField="AreaCode" />
                            <Item dataField="Pincode" />
                            <Item dataField="MobileNo" />
                            <Item dataField="PhoneNo" />
                            <Item dataField="EMailID" />
                            <Item dataField="GSTNo" />
                            <Item dataField="Status" >

                            </Item>

                        </Item>
                    </Form>
                </Editing>
                <Column type="buttons" width={70}>
                    <Button name="edit" />
                    <Button name="delete" />
                </Column>
                <Column
                    dataField={'CustomerName'}
                    width={190}
                    caption={'Customer Name'}

                >
                    <RequiredRule />
                </Column>
                <Column
                    dataField={'ContactPerson'}
                    caption={'Contact Person'}
                />
                <Column
                    dataField={'AreaCode'}
                    caption={'Area Name'}
                >
                    <Lookup dataSource={area} valueExpr="AreaCode" displayExpr="AreaName" />
                    <RequiredRule />
                </Column>
                <Column
                    dataField={'Address1'}
                    caption={'Address 1'}
                    visible={false}
                />
                <Column
                    dataField={'Address2'}
                    caption={'Address 2'}
                    visible={false}
                />
                <Column
                    dataField={'City'}
                    caption={'City'}
                    visible={false}
                >
                    <RequiredRule />
                </Column>
                <Column
                    dataField={'Pincode'}
                    caption={'Pincode'}
                    visible={false}
                >
                    <RequiredRule />
                </Column>
                <Column
                    dataField={'MobileNo'}
                    caption={'Mobile No'}
                > <RequiredRule />
                </Column>
                <Column
                    dataField={'PhoneNo'}
                    caption={'Phone No'}
                    visible={false}
                />
                <Column
                    dataField={'EMailID'}
                    caption={'EMail ID'}
                    visible={false}
                />
                <Column
                    dataField={'GSTNo'}
                    caption={'GST No'}
                    visible={false}
                />
                <Column
                    dataField={'Status'}
                    caption={'Status'}
                >

                </Column>


            </DataGrid>
        </React.Fragment>
    )
}


