export const navigation = [
  {
    text: 'Dashboard',
    path: '/dashboard',
    icon: 'home'
  },

  //Master

  {
    text: 'Master',
    icon: 'inactivefolder',
    items: [
      {
        text: 'Area',
        path: '/area',
      },
      {
        text: 'Brand',
        path: '/brand',
      },
      {

        text: 'Cylinder Type',
        path: '/cylindertype',
      },
      // {

      //   text: 'Role',
      //   path: '/role',
      // },
      {

        text: 'User',
        path: '/user',
      }
    ]
  },

  {
    text: 'Customer',
    icon: 'user',
    items: [
      {
        text: 'Customer Details',
        path: '/customers',
      },
      {
        text: 'Customer Opening',
        path: '/customeropening',
      }
    ]
  },
  {
    text: 'Indent',
    path: '/indent',
    icon: 'cart'
  },
  {
    text: 'Sales',
    path: '/sales',
    icon: 'cart'
  },
  {
    text: 'Reports',
    icon: 'user',
    items: [
      {
        text: 'Current Stock',
        path: '/currentstock',
      },
      {
        text: 'Customer Current Balance',
        path: '/customercurrentbalance',
      }
    ]
  },
];

export const usernavigation = [
  {
    text: 'Customer',
    icon: 'user',
    items: [
      {
        text: 'Customer Details',
        path: '/customers',
      },
      {
        text: 'Customer Opening',
        path: '/customeropening',
      },

    ]
  },
  {
    text: 'Sales',
    path: '/sales',
    icon: 'cart'
  },
  {
    text: 'Reports',
    icon: 'chart',
    items: [
      {
        text: 'Current Stock',
        path: '/currentstock',
      },
      {
        text: 'Customer Current Balance',
        path: '/customercurrentbalance',
      }
    ]
  },
];
