import defaultUser from '../utils/default-user';
import axios from 'axios';
import notify from 'devextreme/ui/notify';


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export async function signIn(userId, password) {
  try {
    let res = await axios(`${apiBaseUrl}/User/Login?UserID=${userId}&Password=${password}`)
    let data = await res.data;
    if (data.Result == "Success") {
      return {
        isOk: true,
        data: data.Data,
      };
    } else {
      return {
        isOk: false,
        message: data.Result
      };
    }
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function getUser() {
  try {
    const lData = JSON.parse(localStorage.getItem('User'))
    if (lData == null || lData == 'undefined') {
      return {
        isOk: false
      };
    } else {
      return {
        isOk: true,
        data: lData
      };
    }
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
