import React, { useEffect, useState } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
    Button,
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Editing,
    Popup,
    Form,
    Item,
    GroupPanel,
    RequiredRule
} from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import axios from 'axios';
import notify from 'devextreme/ui/notify';



export default function User() {
    const [data, setData] = useState([]);
    const [role, setRole] = useState([]);

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    useEffect(() => {
        axios({
            method: 'get',
            url: `${apiBaseUrl}/Role/GetAll`,
        }).then(function (response) {
            if (response.data.Result == "Success") {
                setRole(JSON.parse(response.data.Data));
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'error', 3000);
            return [];
        });
        axios({
            method: 'get',
            url: `${apiBaseUrl}/User/GetAll`,
        }).then(function (response) {
            console.log(response.data);
            if (response.data.Result == "Success") {
                setData(JSON.parse(response.data.Data));
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'right top',
                    at: 'right top',
                },
            }, 'error', 3000);
            return [];
        });
    }, [])

    const RemoveRow = (e) => {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        axios({
            method: 'post',
            url: `${apiBaseUrl}/User/Delete`,
            data: e.data
        }).then(function (response) {

            if (response.data.Result == "Success") {
                notify({
                    message: 'User is Deleted successfully',
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'warning', 3000);
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'right top',
                    at: 'right top',
                },
            }, 'error', 3000);
            return [];
        });
        //  e.preventDefault();
    }
    const AddRow = (e) => {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        axios({
            method: 'post',
            url: `${apiBaseUrl}/User/Save`,
            data: e.data
        }).then(function (response) {

            if (response.data.Result == "Success") {
                notify({
                    message: 'User is added successfully',
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'success', 3000);
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'right top',
                    at: 'right top',
                },
            }, 'error', 3000);
            return [];
        });
        //  e.preventDefault();
    }

    const RowUpdate = (e) => {

        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

        console.log(e.data);

        axios({
            method: 'post',
            url: `${apiBaseUrl}/User/Save`,
            data: e.data
        }).then(function (response) {
            if (response.data.Result == "Success") {
                notify({
                    message: 'User is Updated successfully',
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'success', 3000);
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'right top',
                    at: 'right top',
                },
            }, 'error', 3000);
            return [];
        });
        //  e.preventDefault();

    }



    return (
        <React.Fragment>

            <h4 className={'content-block'}>User Details</h4>

            <DataGrid
                className={'dx-card wide-card'}
                dataSource={data}
                keyExpr="UserCode"
                showBorders={false}
                focusedRowEnabled={true}
                onRowUpdated={RowUpdate}
                onRowInserted={AddRow}
                onRowRemoved={RemoveRow}
            >

                <GroupPanel visible={true} />
                <Editing
                    mode="form"
                    useIcons={true}
                    allowAdding={true}
                    allowUpdating={true}
                    allowDeleting={true}
                    GroupPanel={true}
                >
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2}>
                            <Item dataField="RoleCode" />
                            <Item dataField="UserID" />
                            <Item dataField="UserName" />
                            <Item dataField="Password" editorOptions={{ mode: 'password' }} />
                            <Item dataField="EMailID" />
                            <Item dataField="MobileNo" />
                            <Item dataField="PIN" editorOptions={{ mode: 'password' }} />
                            <Item dataField="Status" />


                        </Item>
                    </Form>
                </Editing>
                <Column type="buttons" width={70}>
                    <Button name="edit" />
                    <Button name="delete" />
                </Column>
                <Column
                    dataField={'RoleCode'}
                    caption={'Role Name'}
                >
                    <Lookup dataSource={role} valueExpr="RoleCode" displayExpr="RoleName" />
                    <RequiredRule />
                </Column>
                <Column
                    dataField={'UserID'}
                    caption={'User ID'}
                ><RequiredRule /></Column>
                <Column
                    dataField={'UserName'}
                    caption={'User Name'}
                >  <RequiredRule /></Column>
                <Column
                    dataField={'Password'}
                    caption={'Password'}
                    visible={false}

                >  <RequiredRule /></Column>
                <Column
                    dataField={'EMailID'}
                    caption={'Email ID'}
                    visible={false}
                ><RequiredRule /></Column>
                <Column
                    dataField={'MobileNo'}
                    caption={'Mobile No'}

                >  <RequiredRule /></Column>
                <Column
                    dataField={'PIN'}
                    caption={'PIN'}
                    visible={false}
                >  <RequiredRule /></Column>
                <Column
                    dataField={'Status'}
                    caption={'Status'}

                />

            </DataGrid>
        </React.Fragment>
    )
}


