import React, { useEffect, useState } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
    Button,
    Column,
    ColumnChooser, ColumnChooserSearch, ColumnChooserSelection, Position,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Editing,
    Popup,
    Form,
    grid,
    Grouping,
    Export,
    Selection,
    GroupPanel,
    RequiredRule,
    PatternRule,
    EmailRule,
    Scrolling,
    LoadPanel
} from 'devextreme-react/data-grid';
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import { Item } from 'devextreme-react/form';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
import { useScreenSize } from '../../utils/media-query';
import { CheckBox } from 'devextreme-react';



export default function Indent() {
    const [data, setData] = useState([]);
    const [cylinderType, setCylinderType] = useState([]);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const { isXSmall, isLarge } = useScreenSize();
    const [loader, setLoader] = useState(false);

    const Indent = {
        Code: null,
        IndentName: '',
        ContactPerson: '',
        Address1: '',
        Address2: '',
        City: '',
        Pincode: '',
        MobileNo: '',
        PhoneNo: '',
        EmailID: '',
        GSTNo: '',
        Status: true,
    };

    useEffect(() => {
        axios({
            method: 'get',
            url: `${apiBaseUrl}/CylinderType/GetAll`,
        }).then(function (response) {
            if (response.data.Result == "Success") {
                setCylinderType(JSON.parse(response.data.Data));
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'error', 3000);
            return [];
        });
        setLoader(true);
        axios({
            method: 'get',
            url: `${apiBaseUrl}/Indent/GetAll`,
        }).then(function (response) {
            setLoader(false);
            if (response.data.Result == "Success") {
                setData(JSON.parse(response.data.Data));
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            setLoader(false);
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'error', 3000);
            return [];
        });

    }, []);



    const RemoveRow = (e) => {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        axios({
            method: 'post',
            url: `${apiBaseUrl}/Indent/Delete`,
            data: e.data
        }).then(function (response) {
            if (response.data.Result == "Success") {
                notify({
                    message: 'Indent is Deleted successfully',
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'warning', 3000);
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'error', 3000);
            return [];
        });
        //  e.preventDefault();
    }
    const AddRow = (e) => {
        console.log(e.data);
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        axios({
            method: 'post',
            url: `${apiBaseUrl}/Indent/Save`,
            data: e.data
        }).then(function (response) {

            if (response.data.Result == "Success") {
                notify({
                    message: 'Indent is added successfully',
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'success', 3000);
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'right top',
                    at: 'right top',
                },
            }, 'error', 3000);
            return [];
        });
        //  e.preventDefault();
    }

    const RowUpdate = (e) => {
        console.log(e.data);
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        axios({
            method: 'post',
            url: `${apiBaseUrl}/Indent/Save`,
            data: e.data
        }).then(function (response) {
            if (response.data.Result == "Success") {
                notify({
                    message: 'Indent is Updated successfully',
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'success', 3000);
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'right top',
                    at: 'right top',
                },
            }, 'error', 3000);
            return [];
        });
        //  e.preventDefault();

    }

    const exportFormats = ['pdf'];
    const onExporting = React.useCallback((e) => {
        const doc = new jsPDF({orientation: 'l',format: 'a3',});

        exportDataGrid({
            jsPDFDocument: doc,
            component: e.component,
            //columnWidths: [60, 40, 35, 25, 15],
            indent: 5,
        }).then(() => {
            doc.save('IndentDetails.pdf');
        });
    });

    const cellPrepared = (e) => {
        if (e.row && e.row.isSelected) {
            e.cellElement.style.backgroundColor = "green";
        }
    };

    const [statusText, setStatusText] = useState(true);

    const statusChanged = (e) => {
        if (e.value == true) {
            setStatusText("Active");
        } else {
            setStatusText("Inactive");
        }
    }

    const handleValueChanged=(e)=>{
        console.log(e);
    }

    const searchEditorOptions = { placeholder: 'Search column' };
    const allowedPageSizes = [10, 20];
    return (
        <React.Fragment>

            <h4 className={'content-block'}>Indent Details</h4>

            <DataGrid
                id="grid"
                className={'dx-card wide-card'}
                dataSource={data}
                keyExpr="IndentCode"
                showBorders={true}
                focusedRowEnabled={true}
                onRowUpdated={RowUpdate}
                onRowInserted={AddRow}
                onRowRemoved={RemoveRow}
                onExporting={onExporting}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnResizingMode={'widget'}
                rowAlternationEnabled={true}
            >
                <Export enabled={true} formats={exportFormats} />
                <Scrolling rowRenderingMode='standard'></Scrolling>
                <Paging defaultPageSize={10} />
                <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={'full'}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true} />
                <LoadPanel enabled={loader} />
                <GroupPanel visible={true} />
                <FilterRow visible={true} />
                <ColumnChooser
                    enabled={true}
                    mode={'select'}
                >
                    <Position
                        my="right top"
                        at="right bottom"
                        of=".dx-datagrid-column-chooser-button"
                    />

                    <ColumnChooserSearch
                        enabled={true}
                        editorOptions={searchEditorOptions} />

                    <ColumnChooserSelection
                        allowSelectAll={true}
                        selectByClick={true}
                        recursive={true} />
                </ColumnChooser>
                <Editing
                    mode="form"
                    useIcons={true}
                    allowAdding={true}
                    allowUpdating={true}
                    allowDeleting={true}
                    GroupPanel={true}
                >
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2}>
                            <Item dataField="IndentDate" />
                            <Item dataField="CylinderTypeCode" />
                            <Item dataField="Empty" />
                            <Item dataField="Loaded" />
                        </Item>
                    </Form>
                </Editing>
                <Column type="buttons" width={70}>
                    <Button name="edit" />
                    <Button name="delete" />
                </Column>
                <Column
                    dataField={'IndentDate'}
                    width={190}
                    caption={'Indent Date'}
                    dataType='date'
                    format={'dd/MM/yyyy'}
                >
                    <RequiredRule />
                </Column>
                <Column
                    dataField={'IndentNo'}
                    caption={'Indent No'}
                />
                
                <Column
                    dataField={'CylinderTypeCode'}
                    caption={'Cylinder Type'}
                >
                    <Lookup dataSource={cylinderType} valueExpr="CylinderTypeCode" displayExpr="CylinderTypeName" />
                    <RequiredRule />
                </Column>
                <Column
                    dataField={'Empty'}
                    caption={'Empty'}
                />
                <Column
                    dataField={'Loaded'}
                    caption={'Loaded'}
                />
               
           </DataGrid>
        </React.Fragment>
    )
}


