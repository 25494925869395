import { DashboardPage, CustomerDetailsPage, CustomerOpeningPage, IndentPage, SalesPage, CustomerCurrentBalancePage, CurrentStockPage } from './pages';
import { AreaPage, BrandPage, CylinderTypePage, RolePage, UserPage } from './pages/master'
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    {
        path: '/customers',
        element: CustomerDetailsPage
    },
    {
        path: '/customeropening',
        element: CustomerOpeningPage
    },

    {
        path: '/dashboard',
        element: DashboardPage
    },
    {
        path: '/area',
        element: AreaPage
    },
    {
        path: '/brand',
        element: BrandPage
    },
    {
        path: '/role',
        element: RolePage
    },
    {
        path: '/user',
        element: UserPage
    },
    {
        path: '/cylindertype',
        element: CylinderTypePage
    }
    ,
    {
        path: '/indent',
        element: IndentPage
    }
    ,
    {
        path: '/sales',
        element: SalesPage
    }
    ,
    {
        path: '/currentstock',
        element: CurrentStockPage
    }
    ,
    {
        path: '/customercurrentbalance',
        element: CustomerCurrentBalancePage
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
