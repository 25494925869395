import React, { useEffect, useState } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
    Column,
    ColumnChooser, ColumnChooserSearch, ColumnChooserSelection, Position,
    Pager,
    Paging,
    FilterRow,
    Export,
    GroupPanel,
    Scrolling,
    LoadPanel
} from 'devextreme-react/data-grid';
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
import { useScreenSize } from 'utils/media-query';


export default function CustomerCurrentBalance() {
    const [data, setData] = useState([]);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        axios({
            method: 'get',
            url: `${apiBaseUrl}/Report/CustomerCurrentBalance`,
        }).then(function (response) {
            setLoader(false);
            if (response.data.Result == "Success") {
                setData(JSON.parse(response.data.Data));
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            setLoader(false);
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'error', 3000);
            return [];
        });
    }, []);

    const exportFormats = ['pdf'];
    const onExporting = React.useCallback((e) => {
        const doc = new jsPDF({orientation: 'l',format: 'a3',});

        exportDataGrid({
            jsPDFDocument: doc,
            component: e.component,
            //columnWidths: [60, 40, 35, 25, 15],
            indent: 5,
        }).then(() => {
            doc.save('CustomerDetails.pdf');
        });
    });

    const searchEditorOptions = { placeholder: 'Search column' };
    const allowedPageSizes = [10, 20];

    return (
        <React.Fragment>

            <h4 className={'content-block'}>Customer Current Balance</h4>

            <DataGrid
                id="grid"
                className={'dx-card wide-card'}
                dataSource={data}
                keyExpr="CustomerCode"
                showBorders={true}
                focusedRowEnabled={true}
                onExporting={onExporting}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnResizingMode={'widget'}
                rowAlternationEnabled={true}
            >
                <Export enabled={true} formats={exportFormats} />
                <Scrolling columnRenderingMode='virtual' rowRenderingMode='standard'></Scrolling>
                <Paging defaultPageSize={10} />
                <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={'full'}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true} />
                <LoadPanel enabled={loader} />
                <GroupPanel visible={true} />
                <FilterRow visible={true} />
                <ColumnChooser
                    enabled={true}
                    mode={'select'}
                >
                    <Position
                        my="right top"
                        at="right bottom"
                        of=".dx-datagrid-column-chooser-button"
                    />

                    <ColumnChooserSearch
                        enabled={true}
                        editorOptions={searchEditorOptions} />

                    <ColumnChooserSelection
                        allowSelectAll={true}
                        selectByClick={true}
                        recursive={true} />
                </ColumnChooser>
                <Column
                    dataField={'CustomerCode'}
                    visible={false}
                />
                <Column
                    dataField={'CustomerName'}
                    caption={'Customer Name'}
                />
                <Column
                    dataField={'CylinderTypeCode'}
                    visible={false}
                />
                <Column
                    dataField={'CylinderTypeName'}
                    caption={'Cylinder Type'}
                />
                <Column
                    dataField={'Balance'}
                />
                <Column
                    dataField={'Loaded'}
                />
            </DataGrid>
        </React.Fragment>
    )
}


