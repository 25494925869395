import React, { useEffect, useState } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
    Button,
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Editing,
    Popup,
    Grouping,
    Export,
    Selection,
    Form,
    Item,
    SimpleItem,
    RequiredRule,
    GroupPanel,
    GroupItem
} from 'devextreme-react/data-grid';
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import { json } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';

export default function CylinderType() {

    const [data, setData] = useState([]);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [brand, setBrand] = useState([]);
    
    useEffect(() => {
        axios({
            method: 'get',
            url: `${apiBaseUrl}/CylinderType/GetAll`,
        }).then(function (response) {
            if (response.data.Result == "Success") {
                setData(JSON.parse(response.data.Data));
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'error', 3000);
            return [];
        });

        axios({
            method: 'get',
            url: `${apiBaseUrl}/Brand/GetAll`,
        }).then(function (response) {
            if (response.data.Result == "Success") {
                setBrand(JSON.parse(response.data.Data));
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'error', 3000);
            return [];
        });

        
    }, [])


    const RemoveRow = (e) => {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        axios({
            method: 'post',
            url: `${apiBaseUrl}/CylinderType/Delete`,
            data: e.data
        }).then(function (response) {
            if (response.data.Result == "Success") {
                notify({
                    message: 'Cylinder Type is Deleted successfully',
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'warning', 3000);
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'right top',
                    at: 'right top',
                },
            }, 'error', 3000);
            return [];
        });
        //  e.preventDefault();
    }
    const AddRow = (e) => {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        axios({
            method: 'post',
            url: `${apiBaseUrl}/CylinderType/Save`,
            data: e.data
        }).then(function (response) {

            if (response.data.Result == "Success") {
                notify({
                    message: 'Cylinder Type is added successfully',
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'success', 3000);
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'right top',
                    at: 'right top',
                },
            }, 'error', 3000);
            return [];
        });
        //  e.preventDefault();
    }

    const RowUpdate = (e) => {

        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        axios({
            method: 'post',
            url: `${apiBaseUrl}/CylinderType/Save`,
            data: e.data
        }).then(function (response) {
            if (response.data.Result == "Success") {
                notify({
                    message: 'Cylinder Type is Updated successfully',
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'success', 3000);
            } else {
                notify({
                    message: response.data.Result,
                    position: {
                        my: 'right top',
                        at: 'right top',
                    },
                }, 'error', 3000);
            }
        }).catch((error) => {
            notify({
                message: "Error fetching data from API:", error,
                position: {
                    my: 'right top',
                    at: 'right top',
                },
            }, 'error', 3000);
            return [];
        });
        //  e.preventDefault();

    }
    const exportFormats = ['pdf'];
    const onExporting = React.useCallback((e) => {
        const doc = new jsPDF();

        exportDataGrid({
            jsPDFDocument: doc,
            component: e.component,
            indent: 5,
        }).then(() => {
            doc.save('CylinderType.pdf');
        });
    });


    return (
        <React.Fragment>

            <h4 className={'content-block'}>Cylinder Type Details</h4>

            <DataGrid
                className={'dx-card wide-card'}
                dataSource={data}
                keyExpr="CylinderTypeCode"
                showBorders={false}
                focusedRowEnabled={true}
                onRowUpdated={RowUpdate}
                onRowInserted={AddRow}
                onRowRemoved={RemoveRow}

            >
                <GroupPanel visible={true} />
                <Editing
                    mode="form"
                    useIcons={true}
                    allowAdding={true}
                    allowUpdating={true}
                    allowDeleting={true}
                    GroupPanel={true}

                >
                    <Form>
                        <Item dataField="BrandCode" />
                        <Item dataField="CylinderTypeName" />
                        <Item dataField="Rate" />
                        <Item dataField="Status" />
                        <Item dataField="OpeningDate" />
                        <Item dataField="Empty" />
                        <Item dataField="Loaded" />
                    </Form>
                </Editing>
                <Column type="buttons" width={70}>
                    <Button name="edit" />
                    <Button name="delete" />
                </Column>
                <Column
                    dataField={'BrandCode'}
                    caption={'Brand Name'}
                >
                    <Lookup dataSource={brand} valueExpr="BrandCode" displayExpr="BrandName" />
                    <RequiredRule />
                </Column>
                <Column
                    dataField={'CylinderTypeName'}
                    caption={'Cylinder Type'}
                >
                    <RequiredRule />
                </Column>

                <Column
                    dataField={'Rate'}
                    caption={'Rate'}
                >
                    <RequiredRule />
                </Column>
                <Column
                    dataField={'OpeningDate'}
                    caption={'OpeningDate'}
                    dataType='date'
                    format={'dd/MM/yyyy'}
                ></Column>
                <Column
                    dataField={'Empty'}
                    caption={'Empty'}
                >
                    <RequiredRule />
                </Column>

                <Column
                    dataField={'Loaded'}
                    caption={'Loaded'}
                >
                    <RequiredRule />
                </Column>

                <Column
                    dataField={'Status'}
                    caption={'Status'}
                />
            </DataGrid>
        </React.Fragment>
    )
}


